<template>
    <CraftLink
        :link="card?.uri"
        :aria-labelledby="card.entry ? `card-button-${id}` : null"
        :aria-describedby="card.entry ? `card-content-${id}`: null"
        :class="small ? 'theme-card--small' : ''"
        class="theme-card"
    >
        <div class="theme-card__image">
            <BaseImage
                sizes="(max-width: 900px) 95vw, 780px"
                :image="card.cardImage?.[0] ? card.cardImage[0] : { url: '/placeholder.svg'}"
                :alt="card.cardImage.title"
            />
        </div>

        <div
            :id="`card-content-${id}`"
            class="theme-card__content"
        >
            <div
                v-if="card.cardTitle"
                class="theme-card__title"
            >
                {{ card.cardTitle }}
            </div>
            <div
                v-else
                class="theme-card__title"
            >
                {{ card.title }}
            </div>


            <div class="theme-card__subtitle">
              <span v-html="card.cardDescription" /> <!-- eslint-disable-line -->
            </div>

            <BaseButton
                v-if="card.entry"
                :id="`card-button-${id}`"
                element="div"
                class="dn-button"
            >
                {{ card?.entry?.[0].text }}
            </BaseButton>
        </div>
    </CraftLink>
</template>

<script setup>
const id = useId();

defineProps({
    card: {
        type: Object,
        required: true,
        default() {
            return { };
        }
    },
    small: {
        type: Boolean,
        required: false,
        default: false
    }
});
</script>

<style lang="less" src="./ThemeCard.less" />
